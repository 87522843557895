import React, { useState } from 'react';

function IncomeRegistration() {
  const [cardName, setCardName] = useState('');
  const [currentBalance, setCurrentBalance] = useState('');
  const [creditLimit, setCreditLimit] = useState('$');
  const [cutOffDay, setCutOffDay] = useState(''); // Día del corte
  const [paymentDay, setPaymentDay] = useState(''); // Día del pago

  const handleCreditLimitChange = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, ''); // Remover todo excepto números y punto
    if (value !== '') {
      value = `$${value}`; // Prefijar con símbolo $
    } else {
      value = '$'; // Asegurar que $ esté presente incluso si el campo se borra
    }
    setCreditLimit(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí procesarías los datos del formulario, por ejemplo, enviar a un backend
    console.log({
      cardName,
      currentBalance,
      creditLimit,
      cutOffDay, // Asegurarse de que esto coincida con la variable de estado actualizada
      paymentDay, // Asegurarse de que esto coincida con la variable de estado actualizada
    });
  };

  return (
    <div className="container">
      <h2>Información de Registro de Tarjeta de Crédito</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="cardName">Nombre de la Tarjeta</label>
          <input
            type="text"
            className="form-control"
            id="cardName"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
            placeholder="Ingresa el nombre de la tarjeta"
          />
        </div>
        <div className="form-group">
          <label htmlFor="currentBalance">Saldo Actual</label>
          <input
            type="text"
            className="form-control"
            id="currentBalance"
            value={currentBalance}
            onChange={(e) => setCurrentBalance(e.target.value.replace(/[^0-9.]/g, ''))}
            placeholder="Ingresa el saldo actual"
          />
        </div>
        <div className="form-group">
          <label htmlFor="creditLimit">Límite de Crédito</label>
          <input
            type="text"
            className="form-control"
            id="creditLimit"
            value={creditLimit}
            onChange={handleCreditLimitChange}
            placeholder="Ingresa el límite de crédito"
          />
        </div>
        <div className="form-group">
          <label htmlFor="cutOffDay">Día de Corte de Cada Mes</label>
          <input
            type="number"
            className="form-control"
            id="cutOffDay"
            value={cutOffDay}
            onChange={(e) => setCutOffDay(e.target.value)}
            min="1"
            max="31"
            placeholder="Ingresa el día de corte (1-31)"
          />
        </div>
        <div className="form-group">
          <label htmlFor="paymentDay">Día de Pago de Cada Mes</label>
          <input
            type="number"
            className="form-control"
            id="paymentDay"
            value={paymentDay}
            onChange={(e) => setPaymentDay(e.target.value)}
            min="1"
            max="31"
            placeholder="Ingresa el día de pago (1-31)"
          />
        </div>
        <button type="submit" className="btn btn-primary">Enviar</button>
      </form>
    </div>
  );
}

export default IncomeRegistration;
