import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import UserRegistration from './components/UserRegistration';
import AccountManagement from './components/AccountManagement';
import IncomeRegistration from './components/IncomeRegistration';
import Statistics from './components/Statistics';
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de que esta ruta sea correcta

function App() {
  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/">MM Finantials App</Link>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item"><Link className="nav-link" to="/">Inicio</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/registro-usuario">Registro de usuarios</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/gestion-cuentas">Gestión de Cuentas bancarias</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/registro-ingresos">Registro de Ingresos</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/estadisticas">Estadísticas</Link></li>
            </ul>
          </div>
        </nav>

        <Routes>
          <Route path="/estadisticas" element={<Statistics />} />
          <Route path="/registro-ingresos" element={<IncomeRegistration />} />
          <Route path="/gestion-cuentas" element={<AccountManagement />} />
          <Route path="/registro-usuario" element={<UserRegistration />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
